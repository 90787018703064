import Link from "next/link";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import RichTextRenderer from "@components/RichTextRenderer";
import ArticlePageWrapper from "@containers/Layout/PageWrapper/ArticlePageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";

import useMediaQuery from "hooks/useMediaQuery";
import { SimpleLinkData } from "types/SimpleLinkData";
import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import pageStyles from "../../styles/page.module.css";
import { StandardPageType_standardpage } from "../StandardPage/queries/contentfulTypes/StandardPageType";
import errorPageStyles from "./ErrorPage.module.css";

const ErrorPage = (props: {
  errorCode: number;
  firstParentPage?: SimpleLinkData | null | undefined;
  pageData: StandardPageType_standardpage;
}) => {
  const { errorCode, firstParentPage, pageData: standardPage } = props;
  const imageData = standardPage?.ivMainImage?.[0];
  const isMobile = useMediaQuery("mobile");

  const context: Context = { ...defaultContext };

  const removeLayoutBottomMargin = shouldRemoveBottomMargin(
    standardPage?.mainAreaBottomCollection?.items
  );

  return (
    <ArticlePageWrapper
      imageData={imageData}
      topImageText={standardPage?.topImageText}
      columns={8}
      relatedContentHeading={standardPage?.relatedContentHeading}
      relatedContentCollection={standardPage?.relatedContentCollection}
      relatedContentSmallImages={standardPage?.relatedContentSmallImages}
      context={context}
      useReCapcha={false}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
    >
      <Grid container mobile={12} desktop={8} desktopLarge={8}>
        <Heading variant="h1" style={{ marginBottom: isMobile ? "1rem" : "1.25rem" }}>
          {standardPage?.heading}
        </Heading>
        {standardPage?.preamble && (
          <Text
            variant={"preamble"}
            children={standardPage?.preamble}
            style={{ marginBottom: "1.25rem" }}
          />
        )}

        <span className={errorPageStyles.errorCode}>Felkod {errorCode}</span>

        {errorCode === 404 && (
          <div className={errorPageStyles.parentLink}>
            {firstParentPage && (
              <Text variant="preamble">
                Kanske hittar du informationen du vill nå via sidan{" "}
                <Link href={firstParentPage?.url} prefetch={false} legacyBehavior={true}>
                  <a className={errorPageStyles.link} target={"_self"} href={firstParentPage?.url}>
                    {firstParentPage?.text}
                  </a>
                </Link>
              </Text>
            )}
          </div>
        )}

        <div className={pageStyles.contentAreaGrid}>
          {standardPage?.mainBody?.json && (
            <RichTextRenderer
              json={standardPage?.mainBody?.json}
              links={standardPage?.mainBody?.links}
              pageTextArea
            />
          )}

          {standardPage?.mainAreaCollection?.items?.length > 0 && (
            <CFContentArea
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              contentAreaItems={standardPage?.mainAreaCollection?.items}
              maxWidth={getContentAreaMaxWidth(8)}
              context={context}
            />
          )}

          {standardPage?.mainBodyBottom?.json && (
            <RichTextRenderer
              json={standardPage?.mainBodyBottom?.json}
              links={standardPage?.mainBodyBottom?.links}
              pageTextArea
            />
          )}

          {standardPage?.mainAreaBottomCollection?.items?.length > 0 && (
            <CFContentArea
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              contentAreaItems={standardPage?.mainAreaBottomCollection?.items}
              maxWidth={getContentAreaMaxWidth(8)}
              context={context}
            />
          )}
        </div>
      </Grid>
    </ArticlePageWrapper>
  );
};

export default ErrorPage;
